
































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import CleanTime from '@/models/CleanTime';
import moment from 'moment-timezone';
import ErrorMessageHandlerMixin from '@/helper/ErrorMessageHandler.mixin';
import {validationMixin} from 'vuelidate';
import {decimal, maxValue, minLength, minValue, required, requiredIf} from 'vuelidate/lib/validators';
import {mixins} from 'vue-class-component';
import {WEEKDAYS, RECURRENCE_TYPES} from '@/Constants';

interface Recurrence {
  daily: string[];
  weekly_monthly: {
    dayOfMonth: string,
    weekday: string,
  };
}

const checkTime = (value: string) => {
  // The length of the time string should not be longer than 5(eg: 00:00) and contain not more than 2 separators
  if (value.length > 5) {
    return false;
  }
  const parts = value.split(':');
  if (parts.length === 2) {
    // The first part should not have more than 2 digits and needs to be greater than 0 and less than 23(24h format)
    if (!(parts[0].length <= 2 && +parts[0] >= 0 && +parts[0] <= 23)) {
      return false;
    }
    // The second part should not have more than 2 digits and needs to be greater than 0 and less than 60(minutes)
    return parts[1].length <= 2 && +parts[1] >= 0 && +parts[1] < 60;
  }
  // If the string has no separator, it's the hours
  return parts[0].length <= 2 && +parts[0] >= 0 && +parts[0] <= 23;
};

/**
 * Component to create or edit a CleanTime
 */
@Component({
  mixins: [validationMixin],
  validations: {
    dateStartPickerValue: {
      required,
    },
    validFromDate: {
      required: requiredIf((vm) => vm.isEditMode && new Date(vm.cleanTime.date).getTime() < Date.now()),
    },
    timeEndPickerValue: {
      required,
      isTimeFormat: checkTime,
      isDifferent(this: any) {
        return !this.hasSameTime;
      },
    },
    timeStartPickerValue: {
      required,
      isTimeFormat: checkTime,
    },
    cleanTime: {
      date: {
        required,
      },
      freq: {
        required,
      },
      interval: {
        required,
        decimal,
        maxValue: maxValue(12),
        minValue: (value, vm) => {
          // Check if the current selected mode is weekly and
          // if our interval is below 2, then return with a min value of 2
          // else return with a min value of 1
          if (vm.freq === 'weekly' && vm.interval < 2) {
            return minValue(2)(value);
          }
          return minValue(1)(value);
        },
      },
      recurrence: {
        required: requiredIf((vm) => {
          return vm.freq === 'monthly';
        }),
      },
      byWeekDay: {
        required,
        minLength: minLength(1),
      },
    },
  },
  components: {
    MenuWithPicker: () => import(
        '@/components/shared/MenuWithPicker.component.vue'),
    ToggleButtonGroup: () => import(
        '@/components/shared/ToggleButtonGroup.component.vue'),
    RJSelect: () => import(
        '@/components/shared/custom-vuetify/RJSelect.vue'),
    RJTextField: () => import(
        '@/components/shared/custom-vuetify/RJTextField.vue'),
  },
})
export default class CleanTimeComponent extends mixins(ErrorMessageHandlerMixin) {

  /**
   * Main model of this component
   */
  @Prop({default: () => new CleanTime()})
  public cleanTime!: CleanTime;
  /**
   * Internal date start string (necessary for picker use)
   */
  public dateStartPickerValue: string = '';
  /**
   * Internal date end string (necessary for picker use)
   */
  public dateEndPickerValue: string = '';

  public validFromDate: string = '';

  /**
   * Min max values for date start picker
   */
  public dateStartMinMax: { min: string, max: string } = {
    min: '',
    max: '',
  };
  /**
   * Min max values for date end picker
   */
  public dateEndMinMax: { min: string, max: string } = {
    min: '',
    max: '',
  };
  private validFromMinMax: { min: string, max: string } = {
    min: moment.tz('CET').endOf('day').toISOString(),
    max: '',
  };

  /**
   * Value of time start picker
   */
  public timeStartPickerValue: string = '';
  /**
   * Value of time end picker
   */
  public timeEndPickerValue: string = '';
  /**
   * Max values for time start picker
   */
  public timeStartMax: string = '';
  /**
   *  Min distance of time between start and end time
   */
  public minTimeDistance: number = 5;
  /**
   * Bool to check if the same time was entered
   */
  public hasSameTime: boolean = false;

  @Prop({default: false})
  public isEditMode!: boolean;

  private recurrence: Recurrence = {
    /**
     * All selected days in weekly view
     */
    daily: [WEEKDAYS[0]],
    weekly_monthly: {
      /**
       * The day that was selected in the daySelector
       */
      weekday: WEEKDAYS[0],
      /**
       * The day of the month in the weekly view
       */
      dayOfMonth: '1',
    },
  };

  /**
   * Returns the minimum Interval
   */
  get getMinInterval(): number {
    return this.cleanTime.freq === 'weekly' ? 2 : 1;
  }

  public get dateEndMessage(): string {
    // Check if this CleanTime is not outdated or
    // if this is a newly created cleantime which has no id
    // then show the normal info text
    if (!this.cleanTime.isOutDated || !this.cleanTime.id) {
      return this.$t('CLEANTIME_OVERVIEW.ENDDATEINFO').toString();
    }

    // if its outdated show the outdated text
    return this.$t('CLEANTIME_OVERVIEW.OUTDATEDINFO').toString();
  }

  /**
   * All available recurrence types
   * @private
   */
  private get recurrenceTypes() {
    return RECURRENCE_TYPES.map((type: string) => ({
      text: this.$t(`CLEAN_TIME_COMPONENT.${type.toUpperCase()}`).toString(),
      value: type,
    }));
  }

  /**
   *  All available weekdays
   */
  public get availableWeekDays(): Array<{ text: string, value: string }> {
    return WEEKDAYS.map((day) => {
      return {
        text: this.$t(`GENERAL.DAYS.${day.toUpperCase()}`).toString(),
        value: day,
      };
    });
  }

  /**
   *  All available weekdays recurrences
   */
  public get availableWeekDaysRecurrenceTypes(): Array<{ text: string, value: string }> {
    return [
      {
        text: this.$t('CLEANTIME_OVERVIEW.FREQUENCY.1', {day: ''}).toString(), value: '1',
      },
      {
        text: this.$t('CLEANTIME_OVERVIEW.FREQUENCY.2', {day: ''}).toString(), value: '2',
      },
      {
        text: this.$t('CLEANTIME_OVERVIEW.FREQUENCY.-2', {day: ''}).toString(), value: '-2',
      },
      {
        text: this.$t('CLEANTIME_OVERVIEW.FREQUENCY.-1', {day: ''}).toString(), value: '-1',
      },
    ];
  }

  /**
   * Assign cleanTime values from recurrence type
   * @param rec the recurrence type. It can either be 'daily', 'weekly' or 'monthly'
   * @private
   */
  private assignFromRecurrence(rec?: typeof RECURRENCE_TYPES[number]) {
    switch (rec || this.cleanTime.freq) {
      case 'daily':
        // set the byWeekDay. If length is 7 the whole week is selected. Then the value is null
        this.cleanTime.byWeekDay = this.recurrence.daily;
        break;
      case 'weekly':
        this.cleanTime.byWeekDay = [this.recurrence.weekly_monthly.weekday];
        break;
      case 'monthly':
        this.cleanTime.byWeekDay = [`${this.recurrence.weekly_monthly.dayOfMonth}${this.recurrence.weekly_monthly.weekday}`];
        this.cleanTime.recurrence = this.recurrence.weekly_monthly.dayOfMonth;
    }

  }

  public created() {
    this.initializeComponent();

    if (!this.isEditMode) {
      this.onAvailableWeekDayClick(this.availableWeekDays[0]);
    }
  }

  /**
   * Event handler for on start date change. Sets the date start display value and real cleanTime.date value.
   */
  public onDateStartChange(date: string) {
    this.triggerValidation('dateStartPickerValue');
    this.checkForSameTime();
    this.cleanTime.date = moment(date).toISOString(true);
    this.dateEndMinMax.min = this.cleanTime.date;
    this.$emit('change');
  }

  /**
   * Event handler for on end date change. Sets the date end display value and real cleanTime.date value.
   */
  public onDateEndChange(date: string) {
    this.checkForSameTime();
    this.cleanTime.dateEnd = moment(date).toISOString(true);
    this.dateStartMinMax.max = this.cleanTime.dateEnd;
    this.validFromMinMax.max = moment(this.cleanTime.dateEnd).endOf('day').toISOString(true);
    this.$emit('change');
  }

  private onValidDateChange(date: string) {
    this.triggerValidation('dateStartPickerValue');
    this.$emit('change');
    this.$emit('changeValidFrom', date);
  }

  /**
   * Event handler for weekday click in daily view. Toggle weekdays
   */
  public onAvailableWeekDayClick(day: { text: string, value: string }) {
    const index = this.recurrence.daily.findIndex((weekDay: string) => weekDay === day.value);
    if (index > -1) {
      // deselect a day
      this.recurrence.daily.splice(index, 1);
    } else {
      // select a day
      this.recurrence.daily.push(day.value);
    }

    // afterwards assign all values, depending on the frequence of the cleanTime
    this.assignFromRecurrence();
  }

  public getDayByString(day: string) {
    return this.availableWeekDays.find((entry) => entry.value === day);
  }

  /**
   * Event handler for start time picker change
   */
  public onTimeStartChange() {
    this.$emit('change');
    this.triggerValidation('timeStartPickerValue');
    this.checkForSameTime();
    if (this.timeEndPickerValue) {
      this.cleanTime.setTime(this.timeStartPickerValue, this.timeEndPickerValue);
      this.cleanTime.getEndTime();
    }

    // calculates the minimum EndTime
    // splits the string into its hours and minutes part
    const splittedStartTime = this.timeStartPickerValue.split(':');
    let hours = parseFloat(splittedStartTime[0]);
    let minutes = parseFloat(splittedStartTime[1]);

    // Adds the min distance on top of the minutes
    minutes += this.minTimeDistance;

    // Checks if our minutes is over or 60 then adds up hours and substracts 60minutes
    if (minutes >= 60) {
      hours++;
      minutes -= 60;
    }
  }

  /**
   * Event handler for end time picker change
   */
  public onTimeEndChange() {
    this.$emit('change');
    this.triggerValidation('timeEndPickerValue');
    this.checkForSameTime();
    if (this.timeStartPickerValue) {
      this.cleanTime.setTime(this.timeStartPickerValue, this.timeEndPickerValue);
    }
  }

  public beforeDestroy() {
    this.resetTextFields();
  }

  /**
   * Resets the TextFields of the DialogBox
   */
  public resetTextFields() {
    this.$emit('change');
    this.dateStartPickerValue = '';
    this.dateEndPickerValue = '';
    this.recurrence.daily = [WEEKDAYS[0]];
    this.recurrence.weekly_monthly = {
      weekday: WEEKDAYS[0],
      dayOfMonth: '',
    };

    this.timeStartPickerValue = '';
    this.timeEndPickerValue = '';

    // Resets the validation (to avoid showing errors by default)
    this.$v.$reset();
  }

  /**
   * Checks if the dates and the times are the same and shows an error message if so
   */
  public checkForSameTime() {
    if (this.timeStartPickerValue === '' || this.timeEndPickerValue === '' ||
        this.dateStartPickerValue === '' || this.dateEndPickerValue === '') {
      this.hasSameTime = false;
      return;
    }

    // set hasSameTime boolean according to if start and end have the same time
    this.hasSameTime = this.timeStartPickerValue === this.timeEndPickerValue;
  }

  @Watch('cleanTime', {deep: true, immediate: true})
  private input(newVal: CleanTime, oldVal: CleanTime) {
    // Only 'initialize' new cleanTime values, if the oldVal and newVal is not the same cleanTime
    if (!oldVal || oldVal.id !== newVal.id) {
      this.validFromMinMax.max = moment(this.cleanTime.dateEnd).endOf('day').toISOString(true) || '';
      this.validFromMinMax.min = moment.max(moment(this.cleanTime.date), moment.tz('CET')).endOf('day').toISOString(true) || '';
      this.initializeComponent();
      if (this.cleanTime.freq === 'monthly') {
        const subStringLength = this.cleanTime.byWeekDay![0][0] === '-' ? 2 : 1;
        this.recurrence.weekly_monthly.dayOfMonth = (this.cleanTime.byWeekDay?.[0] || '').substr(0, subStringLength);
        this.recurrence.weekly_monthly.weekday = (this.cleanTime.byWeekDay?.[0]?.substr(subStringLength)) || WEEKDAYS[0];
      } else if (this.cleanTime.freq === 'weekly') {
        this.recurrence.weekly_monthly.weekday = this.cleanTime.byWeekDay?.[0] || WEEKDAYS[0];
      } else {
        this.recurrence.daily = this.cleanTime.byWeekDay!;
      }
    }
    // emit cleanTime and vuelidate data by changing cleanTime
    this.$emit('input', {
      cleanTime: this.cleanTime,
      vuelidate: this.$v,
    });
  }

  /**
   *  Sets all necessary values
   */
  private initializeComponent() {
    // set values for time range date picker
    if (!this.cleanTime.date) {
      this.cleanTime.date = moment().toISOString();
    }
    this.dateStartPickerValue = this.cleanTime.date;
    this.dateEndPickerValue = this.cleanTime.dateEnd as string;
    this.dateStartMinMax.min = moment().toISOString(true);
    this.dateStartMinMax.max = this.cleanTime.dateEnd as string;
    this.dateEndMinMax.min = moment(this.cleanTime.date).toISOString(true) || moment().toISOString(true);
    this.validFromMinMax.min = moment.max(moment(this.cleanTime.date), moment.tz('CET')).endOf('day').toISOString(true);
    if (this.cleanTime.dateEnd) {
      this.validFromMinMax.max = moment(this.cleanTime.dateEnd).endOf('day').toISOString(true);
    }

    // set value for time picker
    this.timeStartPickerValue = this.cleanTime.getStartTime() ?? '';
    this.timeEndPickerValue = this.cleanTime.getEndTime() ?? '';
    this.timeStartMax = this.timeEndPickerValue;
  }
}
